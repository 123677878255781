import axiosInstance from './axios.config.onscore';

const RESTAPI_VERSION = '/v1';
const SMS_PATH = 'sms';

export const smsService = {
    sendSMS: (payload) => {
        return axiosInstance.post(`${RESTAPI_VERSION}/${SMS_PATH}`, payload);
    },
    
};

export default smsService;