import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './styles/styles.scss';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { checkLogin, keycloak } from './keycloak';

import './index.css';
//import reportWebVitals from './reportWebVitals';

import App from './App';
import './i18n';


const root = ReactDOM.createRoot(
  document.getElementById('root') || document.createElement('div')
);

const renderApp = () =>
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );

checkLogin(renderApp);