import Keycloak from 'keycloak-js';
import { KEYCLOAK_BASE_URL, KEYCLOAK_REALM, KEYCLOAK_CLIENT_ID, getEnv } from './env';
import axiosInstance from './services/axios.config.onscore';

export const keycloak = new Keycloak({
    url: `${getEnv(KEYCLOAK_BASE_URL)}`,
    realm: `${getEnv(KEYCLOAK_REALM)}`,
    clientId: `${getEnv(KEYCLOAK_CLIENT_ID)}`,
});

export const checkLogin = async (onAuthenticatedCallback) => {
    const APP_MACRO_MULTISCORING_ROLE_NAME = 'APP_MACRO_MULTISCORING';
    const RESTAPI_VERSION = '/v1';
    const BASE_ENDPOINT_NAME = 'profiles/me';

    const getUserPermissions = () => {
        return axiosInstance.get(`${RESTAPI_VERSION}/${BASE_ENDPOINT_NAME}`, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
            },
        });
    };

    try {
        const authenticated = await keycloak.init({
            onLoad: 'login-required',
            checkLoginIframe: false,
            pkceMethod: 'S256',
        });
        if (authenticated) {
            localStorage.setItem('access_token', keycloak.token);
            localStorage.setItem('refresh_token', keycloak.refreshToken);
            let loggedInUserRoles = (await getUserPermissions()).data.roles;
            if (!loggedInUserRoles.includes(APP_MACRO_MULTISCORING_ROLE_NAME)) {
                await keycloak.logout();
            }
            onAuthenticatedCallback();
        }
    } catch (err) {
        console.log(err);
    }
};

export default keycloak;
