import React, { useEffect, useState, Suspense } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Loading from '../../components/loader/Loader';
import bgMobile from '../../assets/background-landing-mobile.svg';
import logoColor from '../../assets/logoColor.svg';
import backgroundImage from '../../assets/mujer-joven-tecnologia-casera.jpg';
import Hidden from '@material-ui/core/Hidden';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { keycloak } from '../../keycloak';

const useStyles = makeStyles((theme) => ({
    root: {
        height: 'calc(100vh - 80px)',
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
        justifyContent: 'space-between',
        height: '80px',
    },
    toolbarLogo: {
        height: '35px',
    },
    link: {
        margin: theme.spacing(1, 1.5),
        //alignItems: 'center',
        color: '#092f57',
    },
    image: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#092f57',
        backgroundSize: '100%',
        backgroundPosition: 'bottom right',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

const handleLogout = () => {
    keycloak.logout();
}

const LandingTemplate = (props) => {
    const { showBanner, noContainer, financialProductRequest } = props;
    const [bnplOperation, setBnplOperation] = useState(null);
    const classes = useStyles();

    return (
        <Suspense fallback={<Loading />}>
            <AppBar position="fixed" color="inherit" elevation={0} className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <img src={logoColor} className={classes.toolbarLogo} alt="" />
                    
                    <Link onClick={handleLogout} color="secondary" className={classes.link}>
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}>
                                <ExitToAppIcon style={{ fontSize: 20 }} /> <span style={{ fontSize: 14 }}>Salir</span>
                            </span>
                        </Link>

                        
                    
                </Toolbar>
            </AppBar>
            <Toolbar style={{ height: '80px' }} />

            {showBanner && (
                <Hidden smUp>
                    <Grid item xs={12} sm={false}>
                        <img src={bgMobile} alt="" width="100%" />
                    </Grid>
                </Hidden>
            )}
            <Grid container component="main" className={classes.root}>
                <Grid item sm={4} className={classes.image} />
                {noContainer ? (
                    <Grid item xs={12} sm={8} component={Paper} elevation={0} square style={{ backgroundColor: '#fafafa' }}>
                        {props.children}
                    </Grid>
                ) : (
                    <Grid container item xs={12} sm={8} component={Paper} elevation={0} square style={{ backgroundColor: '#fafafa' }}>
                        {props.children}
                    </Grid>
                )}
            </Grid>
        </Suspense>
    );
};

export default LandingTemplate;
