import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { LoginPage } from './pages/LoginPage';
import { NoRetryable } from './pages/NoRetryablePage';
import theme from './styles/resources/themes/theme';
function App() {
      return (
            <ThemeProvider theme={theme}>
                  <Router>
                        <Switch>
                              <Route
                                    path="/noRetryable/:errorText/:bnplOperationId"
                                    render={(props) => <NoRetryable {...props} />}
                              />{' '}
                              <Route
                                    path="/noRetryable/:errorText"
                                    render={(props) => <NoRetryable {...props} />}
                              />{' '}
                              <Route path="/" render={(props) => <LoginPage {...props} />} />
                              
                        </Switch>
                  </Router>
            </ThemeProvider>
      );
}

export default App;
