import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const TextMaskCustom = (props) => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            mask={[/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            // showMask
        />
    );
};

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
    textField: {
        margin: theme.spacing(2, 0, 2, 0),
    },
}));

export const CellphoneTextField = (props) => {
    const classes = useStyles();
    const { onChange, loading } = props;
    const [number, setNumber] = useState(null);
    const [phoneError, setPhoneError] = useState(false);

    const isNumberValid = (number) => {
        return number && number.length >= 10 && !/^15/.test(number);
    };

    const handleChangeNumber = (e) => {
        const number = e.target.value.trim();
        setNumber(number);
        setPhoneError(false);
        onChange(number, isNumberValid(number));
    };

    return (
        <Grid container direction="column">
            <Grid item xs={12}>
                <TextField
                    label="Teléfono celular"
                    required={true}
                    fullWidth={true}
                    onChange={handleChangeNumber}
                    name="phone"
                    value={number}
                    error={phoneError}
                    helperText={phoneError ? 'Formato de celular inválido. Volvé a ingresarlo' : ''}
                    InputProps={{
                        inputComponent: TextMaskCustom,
                    }}
                    disabled={loading}
                    variant="outlined"
                    color="secondary"
                    className={classes.textField}
                />
            </Grid>

        </Grid>
    );
};
