import axiosInstance from './axios.config.onscore';

const RESTAPI_VERSION = '/v1';
const PROCESS_INSTANCES_PATH = 'macro';
const RISK_ANALYSIS_FOLDER_PATH = 'risk-analysis-folders';

export const riskAnalysisFolderService = {
    evaluate: (payload) => {
        return axiosInstance.post(`${RESTAPI_VERSION}/${PROCESS_INSTANCES_PATH}/${RISK_ANALYSIS_FOLDER_PATH}`, payload);
    },
    
};

export default riskAnalysisFolderService;