import React, { useEffect, useState, Suspense } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Loading from '../../components/loader/Loader';
import logo from '../../assets/logo.svg';
import backgroundImage from '../../assets/background-validation.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        height: 'calc(100vh - 80px)',
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: '#092f57',
    },
    toolbar: {
        justifyContent: 'space-between',
        height: '80px',
    },
    toolbarLogo: {
        height: '35px',
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },
    image: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#092f57',
        backgroundSize: '50%',
        backgroundPosition: 'top right',
    },
    paper: {
        margin: theme.spacing(5, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    noPaper: {
        marginTop: 50,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

const MacroStepPage = (props) => {
    const { noBackground, noMargin } = props;
    const classes = useStyles();
    
    return (
        <>
            <Suspense fallback={<Loading />}>
                <AppBar position="fixed" color="secondary" elevation={0} className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>
                        <img src={logo} className={classes.toolbarLogo} alt="" />
                        
                    </Toolbar>
                </AppBar>
                <Toolbar style={{ height: '80px' }} />
                <Grid container component="main" className={classes.root}>
                    {noMargin ? (
                        <Grid item container xs={12} component={Paper} className={classes.noPaper} elevation={0} square>
                            {props.children}
                        </Grid>
                    ) : (
                        <Grid item container xs={12} component={Paper} elevation={0} square className={noBackground ? classes.paper : classes.image}>
                            {props.children}
                        </Grid>
                    )}
                </Grid>
            </Suspense>
        </>
    );
};

export default MacroStepPage;
