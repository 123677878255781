import React, { useEffect, useMemo, useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Hidden, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import Toaster from '../components/alerts/Toaster';
import Loader from '../components/loader/Loader';
import '../styles/components/offerSelection.scss';
import { useTranslation } from 'react-i18next';
import { numberToCurrencyText } from '../components/utils/numberUtils';
import smsService from '../services/sms.service';

const MAX_WAIT_TIME = 60;

export default function OfferPage(props) {

    const { offer, customerInfo, onRestart } = props;
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(null);
    const [imgSrc, setImgSrc] = useState(null);
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    const [showTimerSMS, setShowTimerSMS] = useState(false);
    const [validationCodeSentSms, setValidationCodeSentSms] = useState(false);
    const [retryCodeSentSms, setRetryCodeSentSms] = useState(false);
    const [seconds, setSeconds] = useState(MAX_WAIT_TIME);

    useEffect(() => {
        setImgSrc(require(`./../assets/tc_paquetes/internacional.png`));
    }, []);

    useEffect(() => {
        if (seconds <= 0) {
            //setRetryCodeSentSms(true);

            setShowTimerSMS(false);

            setValidationCodeSentSms(false);
            setSeconds(MAX_WAIT_TIME);
        }
    }, [seconds]);

    useEffect(() => {
        if (validationCodeSentSms) {
            const timer = setInterval(() => {
                setSeconds(seconds - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
    });

    const sendSMS = async () => {
        setLoading(true);
        try {
            const payload = {
                templateName: "sms_multiscoring_offer",
                variables: {url: offer.additionalData.creditCardOnboardingUrl},
                cellphoneNumberList: [{number: customerInfo.cellphone}]
            };
            await smsService.sendSMS(payload);

            setValidationCodeSentSms(true);
            setShowTimerSMS(true);
            //setShowManualInputMessage(false);
            
           
        } catch (error) {
            const errorMessage = 'No pudimos enviar el SMS. Reintentá en unos segundos';
            if (error.response && error.response.data) {
                setToast({ message: t(`WORKFLOW_ERROR.${error.response.data.errorCode}.DESCRIPTION`) || errorMessage });
            }

            setValidationCodeSentSms(false);
            setShowTimerSMS(false);
        } finally {
            setLoading(false);
        }
        
    };
    
    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleToasterClose = () => {
        setOpen(false);
    };
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const handleClickOpenPopup = () => {
        setOpenPopup(true);
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    const handleOpenTermsPopup = () => {
        setOpenTCPopup(true);
    };

    const handleCloseTermsPopup = () => {
        setOpenTCPopup(false);
    };

    const availableAmount = numberToCurrencyText(offer.creditCardLimit); //selectedProduct ? numberToCurrencyText(selectedProduct.OfferLimit) : null;


    return (
        
        <Grid container>
                    <Loader loading={loading} />

                    <Grid >
                        <div >
                            
                                <Grid item container direction="row"  style={{ zIndex: '1' }}>
                                    
                                    
                                        <Grid item xs={12} md={12} className="img-highlight mt-100">
                                            <img src={imgSrc} alt="" className="credit-card-img" />
                                        </Grid>
                                        <Grid item xs={12} md={12} className="mt-1">
                                            
                                            <p className="banner-highlight">
                                            El cliente tiene disponible una tarjeta con un límite de {' '}
                                                <b>{availableAmount}</b>{' '}.
                                            </p>

                                        </Grid>
                                        <Grid item xs={12} md={12} className="mt-2">
                                            
                                            <p className="banner-highlight-sub">
                                                Compartile la oferta para que pueda solicitar su tarjeta.
                                            </p>
                                        </Grid>

                                        <Grid container justifyContent="center" className="mt-3">
                                            <Button
                                                variant="contained"
                                                disableElevation={true}
                                                fullWidth={true}
                                                color="primary"
                                                onClick={sendSMS}
                                                disabled={showTimerSMS}>
                                                Enviar por SMS
                                            </Button>
                                        </Grid>



                                        <Grid item xs={12} sm={12} className="mt-2">
                                            {showTimerSMS && (
                                                <Box className="mt-2 mb-2">
                                                    <i className="fs-12 fw-400" hidden={retryCodeSentSms && !validationCodeSentSms}>
                                                        {' '}
                                                        (Aguarde {seconds} segundos para reenviar){' '}
                                                    </i>{' '}
                                                    {retryCodeSentSms && !validationCodeSentSms && (
                                                        <Box className="mt-1 fs-14">
                                                            
                                                            <a href="#" className="link fs-14 mt-1 fw-400 color-link-blue no-text-transform" onClick={sendSMS}>
                                                                Reenviar
                                                            </a>
                                                        </Box>
                                                    )}
                                                </Box>
                                            )}
                                        </Grid>{' '}












                                        <Grid container justifyContent="center" className="mt-1 mb-1">
                                            <Button
                                                variant="outlined"
                                                disableElevation={true}
                                                fullWidth={true}
                                                color="primary"
                                                onClick={onRestart}>
                                                Nueva consulta
                                            </Button>
                                        </Grid>


                                </Grid>
                                
                                <Grid item>
                                    <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                                </Grid>
                        
                        </div>
                    </Grid>
                    </Grid>
            
    );
};
