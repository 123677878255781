import React, { useState, useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MacroStepPage from './shared/MacroStepPage';
import Loader from '../components/loader/Loader';
import errorImg from '../assets/Error.svg';
import Toaster from '../components/alerts/Toaster';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    title: {
        margin: theme.spacing(5, 0, 0, 0),
        textAlign: 'center',
    },
    description: {
        margin: theme.spacing(5, 0, 5, 0),
        textAlign: 'center',
    },
    button: {
        margin: theme.spacing(5, 5, 5, 5),
    },
}));

export const NoRetryable = (props) => {
    const history = useHistory();
    const { errorText } = props.match.params;
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [commerceOperationData, setCommerceOperationData] = useState(null);
    const classes = useStyles();

    const handleToasterClose = () => {
        setOpen(false);
    };

    const handleRestart = (event) => {
        history.push(`/`);
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    return (
        <MacroStepPage {...props} noBackground={true}>
            <Loader loading={loading} />
            <Grid style={{ display: 'flex', justifyContent: 'center' }} container elevation={0} square alignItems="center">
                <Grid item xs={12} container justifyContent="center">
                    <img src={errorImg} className="mt-2" alt="" />
                </Grid>
                <Grid item container className="mt-4 center" xs={12} alignItems="center">
                    <h2 style={{ textAlign: 'center' }}>{t('NO_RETRYABLE_ERROR.' + errorText + '.TITLE')}</h2>
                </Grid>

                <Grid item container className="mt-4 center" xs={12} sm={10}>
                    <div className="mb-footer startButton center mb-20-web mt-20-web">
                        <Grid style={{ display: 'flex', justifyContent: 'center' }} container justifyContent="center">
                            <Button
                                style={{ maxWidth: '400px', width: '50%', justifySelf: 'center' }}
                                variant="contained"
                                className={classes.button}
                                fullWidth={true}
                                color="primary"
                                disableElevation={true}
                                onClick={handleRestart}>
                                Nueva consulta +
                            </Button>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
        </MacroStepPage>
    );
};
